<template>
    <div>
        <!-- <h1 class="text-uppercase text-center text-primary">Prochainement</h1> -->
        <b-card class="min-vh-100"
        >
            <b-row>
                <b-col sm="4">
                    <img class="img-fluid" src="@/assets/images/universign-logo.png" />
                </b-col>
                <b-col sm="4">
                    <h3 style="line-height:100px;height:100px;">SERVICE DE SIGNATURE ELECTRONIQUE</h3>
                </b-col>
            </b-row>
            <hr>
            <b-row>
                <b-col>
                    <b-alert show variant="success" class="p-3 text-center" style="color:#000 !important;">Documents non accessibles : Refus de signature.</b-alert>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>

<script>
import { BCard,BRow,BCol,BAlert } from 'bootstrap-vue'
export default {
    components: {
    BCard,
    BRow,
    BCol,
    BAlert
  },
}
</script>

<style>
.img-proche
{
    padding:5% 10%;
}
@media(max-width:768px)
{
    .img-proche
    {
        margin-top:50px;
        padding: 0;
    }
}
</style>